import React from 'react'
import ContentEditable from 'react-contenteditable'
import SelectMenu from '../SelectMenu'
// import { useMemo } from 'react'
// Import the Slate editor factory.
// import { createEditor } from 'slate'
// import { useCallback } from 'react'
// import { useState } from 'react'
// import { Editor, Transforms } from 'slate'

// Import the Slate components and React plugin.
// import { Slate, Editable, withReact } from 'slate-react'

const CodeElement = (props) => {
  return (
    <pre {...props.attributes}>
      <code>{props.children}</code>
    </pre>
  )
}

const DefaultElement = (props) => {
  return <p {...props.attributes}>{props.children}</p>
}

// const CustomEditor = {
// isBoldMarkActive(editor) {
//   const [match] = Editor.nodes(editor, {
//     match: (n) => n.bold === true,
//     universal: true,
//   })

//   return !!match
// },

// isCodeBlockActive(editor) {
//   const [match] = Editor.nodes(editor, {
//     match: (n) => n.type === 'code',
//   })

//   return !!match
// },

// toggleBoldMark(editor) {
//   const isActive = CustomEditor.isBoldMarkActive(editor)
//   Transforms.setNodes(
//     editor,
//     { bold: isActive ? null : true },
//     { match: (n) => Text.isText(n), split: true }
//   )
// },

// toggleCodeBlock(editor) {
//   const isActive = CustomEditor.isCodeBlockActive(editor)
//   Transforms.setNodes(
//     editor,
//     { type: isActive ? null : 'code' },
//     { match: (n) => Editor.isBlock(editor, n) }
//   )
// },
// }

const setCaretToEnd = (element) => {
  const range = document.createRange()
  const selection = window.getSelection()
  range.selectNodeContents(element)
  range.collapse(false)
  selection.removeAllRanges()
  selection.addRange(range)
  element.focus()
}

const getCaretCoordinates = () => {
  let x, y
  const selection = window.getSelection()
  if (selection.rangeCount !== 0) {
    const range = selection.getRangeAt(0).cloneRange()
    range.collapse(false)
    const rect = range.getClientRects()[0]
    if (rect) {
      x = rect.left
      y = rect.top
    }
  }
  return { x, y }
}

// const editor = createEditor()

// const initialValue = [
//   {
//     type: 'paragraph',
//     children: [{ text: 'A line.' }],
//   },
// ]

export class EditableBlock extends React.Component {
  constructor(props) {
    super(props)
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this)

    this.onKeyUpHandler = this.onKeyUpHandler.bind(this)
    this.openSelectMenuHandler = this.openSelectMenuHandler.bind(this)
    this.closeSelectMenuHandler = this.closeSelectMenuHandler.bind(this)
    this.tagSelectionHandler = this.tagSelectionHandler.bind(this)

    this.contentEditable = React.createRef()
    this.state = {
      htmlBackup: null,
      html: '',
      tag: 'p',
      previousKey: '',
      selectMenuIsOpen: false,
      selectMenuPosition: {
        x: null,
        y: null,
      },
    }
  }

  onKeyUpHandler(e) {
    if (e.key === '/') {
      this.openSelectMenuHandler()
    }
  }

  renderElement(props) {
    switch (props.element.type) {
      case 'code':
        return <CodeElement {...props} />
      default:
        return <DefaultElement {...props} />
    }
  }

  openSelectMenuHandler() {
    const { x, y } = getCaretCoordinates()
    this.setState({
      selectMenuIsOpen: true,
      selectMenuPosition: { x, y },
    })
    document.addEventListener('click', this.closeSelectMenuHandler)
  }

  closeSelectMenuHandler() {
    this.setState({
      htmlBackup: null,
      selectMenuIsOpen: false,
      selectMenuPosition: { x: null, y: null },
    })
    document.removeEventListener('click', this.closeSelectMenuHandler)
  }

  tagSelectionHandler(label) {
    setCaretToEnd(this.contentEditable.current)
    this.closeSelectMenuHandler()
    this.props.runCode(this.state.htmlBackup)
    this.setState({ html: this.state.htmlBackup })
  }
  // this.setState(
  // { /*tag: tag, html: this.state.htmlBackup */ //html: label },
  //   () => {
  //     setCaretToEnd(this.contentEditable.current)
  //     this.closeSelectMenuHandler()
  //   }
  // )
  // }

  onKeyDownHandler(e) {
    if (e.key === '/') {
      this.setState({ htmlBackup: this.state.html })
    }

    if (e.shiftKey) {
      if (e.key === 'Enter') {
        e.preventDefault()
        this.props.addBlock({
          id: this.props.id,
          ref: this.contentEditable.current,
        })
      }
    }

    if (e.metaKey) {
      if (e.key === 'ArrowUp') {
        this.props.goPreviousBlock({
          id: this.props.id,
          ref: this.contentEditable.current,
        })
      }

      if (e.key === 'ArrowDown') {
        this.props.goNextBlock({
          id: this.props.id,
          ref: this.contentEditable.current,
        })
      }
    }

    if (e.key === 'Backspace' && !this.state.html) {
      e.preventDefault()
      this.props.deleteBlock({
        id: this.props.id,
        ref: this.contentEditable.current,
      })
    }

    this.setState({ previousKey: e.key })
  }

  componentDidMount() {
    this.setState({ html: this.props.html, tag: this.props.tag })

    // if (this.props.is_focused) {
    //   this.contentEditable.current.focus()
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    const htmlChanged = prevState.html !== this.state.html
    const tagChanged = prevState.tag !== this.state.tag

    if (htmlChanged || tagChanged) {
      this.props.updatePage({
        id: this.props.id,
        html: this.state.html,
        tag: this.state.tag,
      })
    }
  }

  onChangeHandler(e) {
    this.setState({ html: e.target.value })
  }

  render() {
    return (
      <>
        {this.state.selectMenuIsOpen && (
          <SelectMenu
            position={this.state.selectMenuPosition}
            onSelect={this.tagSelectionHandler}
            close={this.closeSelectMenuHandler}
          />
        )}

        <ContentEditable
          className="Block"
          innerRef={this.contentEditable}
          html={this.state.html}
          tagName={this.state.tag}
          onChange={this.onChangeHandler}
          onKeyDown={this.onKeyDownHandler}
          onKeyUp={this.onKeyUpHandler}
        />
      </>
    )
  }
}

// export default EditableBlock
// // {

// // }
