import React from 'react'
import { EditableBlock } from '../EditableBlock'
import axios from 'axios'
import debounce from 'lodash.debounce'
import SideMenu from '../SideMenu'
import NavBarMenu from '../NavBarMenu'

function dec2hex(dec) {
  return dec.toString(16).padStart(2, '0')
}

function generateId(len) {
  var arr = new Uint8Array((len || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}

const uid = () => {
  return generateId(24)
}

const setCaretToEnd = (element) => {
  const range = document.createRange()
  const selection = window.getSelection()
  range.selectNodeContents(element)
  range.collapse(false)
  selection.removeAllRanges()
  selection.addRange(range)
  element.focus()
}

class EditablePage extends React.Component {
  constructor(props) {
    super(props)
    this.goPreviousBlockHandler = this.goPreviousBlockHandler.bind(this)
    this.goNextBlockHandler = this.goNextBlockHandler.bind(this)
    this.updatePageHandler = this.updatePageHandler.bind(this)
    this.addBlockHandler = this.addBlockHandler.bind(this)
    this.deleteBlockHandler = this.deleteBlockHandler.bind(this)
    this.onTapSideMenuHandler = this.onTapSideMenuHandler.bind(this)
    this.runCodeHandler = this.runCodeHandler.bind(this)
    this.state = { blocks: [], currentBlock: null }
  }

  componentDidMount() {
    // this.fetchBlocks = this.fetchBlocks.bind(this)
    this.fetchBlocks('6344040ce1cda2bf4d104ccf')
  }

  fetchBlocks = (ID) => {
    // console.log('http://127.0.0.1:8787/api/noter/' + ID)
    axios
      .get('/document1')
      .then((response) => {
        return response.data[0]
      })
      .then((data) => {
        let arr = []
        data.blocks.forEach((element) => {
          arr.push({
            id: element._id,
            html: element.html,
            tag: 'p',
            is_focused: data.lastFocusBlockID === element._id,
          })
        })
        return arr
      })
      .then((blocks) => {
        if (blocks.length === 0) {
          return [{ id: uid(), html: '', tag: 'p', local_id: null }]
        } else {
          return blocks
        }
      })
      .then((blocks) => {
        this.setState({ blocks: blocks }, () => {})
      })
  }

  insertBlock = debounce(async (block, position) => {
    await axios.post('/insert/' + 'document1' + '/' + position, block)
  }, 10)

  postBlock = debounce(async (block, position) => {
    await axios.post('/document1' + '/' + position, block)
  }, 350)

  onTapSideMenuHandler(ID) {
    this.fetchBlocks(ID._id)
  }

  updatePageHandler(updatedBlock) {
    const blocks = this.state.blocks
    const index = blocks.map((b) => b.id).indexOf(updatedBlock.id)
    const updatedBlocks = [...blocks]
    // console.log('updatedBlock.html ' + updatedBlock.html)
    // console.log('updatedBlocks[index].html ' + updatedBlocks[index].html)
    if (updatedBlock.html !== updatedBlocks[index].html) {
      this.postBlock(updatedBlock, index)
    }

    updatedBlocks[index] = {
      ...updatedBlocks[index],
      tag: updatedBlock.tag,
      html: updatedBlock.html,
    }

    this.setState({
      blocks: updatedBlocks,
    })
  }

  addBlockHandler(currentBlock) {
    const newBlock = { id: uid(), html: '', tag: 'p' }
    const blocks = this.state.blocks
    const index = blocks.map((b) => b.id).indexOf(currentBlock.id)
    let updatedBlocks = blocks

    updatedBlocks = [...blocks]

    updatedBlocks.splice(index + 1, 0, newBlock)

    this.setState({ blocks: updatedBlocks }, () => {
      currentBlock.ref.nextElementSibling.focus()
    })

    this.insertBlock(newBlock, index + 1)
  }

  goPreviousBlockHandler(currentBlock) {
    const previousBlock = currentBlock.ref.previousElementSibling
    if (previousBlock) {
      setCaretToEnd(previousBlock)
      previousBlock.focus()
    }
  }

  goNextBlockHandler(currentBlock) {
    if (currentBlock.ref.nextElementSibling !== null) {
      currentBlock.ref.nextElementSibling.focus()
    }
  }

  runCodeHandler(code) {
    const cleanCode = code
      .replace(/<\/?[^`]+?\/?>/gim, '\n')
      .replace(/\n[\s]*\n/gim, '\n')
      .replace('&nbsp;', ' ')
    console.log(cleanCode)
    axios
      .post('/codeRun', { code: cleanCode })
      .then((response) => console.log(response.data))
  }

  deleteBlockHandler(currentBlock) {
    const previousBlock = currentBlock.ref.previousElementSibling
    if (previousBlock) {
      const blocks = this.state.blocks
      const index = blocks.map((b) => b.id).indexOf(currentBlock.id)
      const updatedBlocks = [...blocks]
      updatedBlocks.splice(index, 1)
      this.setState({ blocks: updatedBlocks }, () => {
        setCaretToEnd(previousBlock)
        previousBlock.focus()
      })
      console.log('DELETING ' + currentBlock.id)
      axios.delete('document1' + '/' + currentBlock.id)
    }
  }

  render() {
    return (
      <div>
        <NavBarMenu />

        <div class="columns">
          <div class="column is-1" id="sticky">
            <SideMenu
              blocks={this.state.blocks}
              onTap={this.onTapSideMenuHandler}
            />
          </div>

          <div className="Page" class="column is-half is-offset-1">
            {this.state.blocks.map((block, key) => {
              return (
                <EditableBlock
                  key={block.id}
                  id={block.id}
                  tag={block.tag}
                  html={block.html}
                  is_focused={block.is_focused}
                  updatePage={this.updatePageHandler}
                  addBlock={this.addBlockHandler}
                  deleteBlock={this.deleteBlockHandler}
                  goPreviousBlock={this.goPreviousBlockHandler}
                  goNextBlock={this.goNextBlockHandler}
                  runCode={this.runCodeHandler}
                />
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default EditablePage
