import React from 'react'
import axios from 'axios'
// import debounce from 'lodash.debounce'

class SideMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = { pages: [] }
  }

  componentDidMount() {
    this.fetchPages()
  }

  fetchPages = () => {
    axios.get().then((response) => {
      this.setState({ pages: response.data }, () => {})
    })
  }

  render() {
    return (
      <div className="SideMenu">
        <aside class="menu">
          <p class="menu-label">General</p>
          <ul class="menu-list">
            <li>
              {this.state.pages.forEach((block) => {
                console.log(block)
                return (
                  // <li onClick={() => this.props.onTap(block)}>
                  <a>sdasdasd{block}</a>
                  // </li>
                )
              })}
            </li>
          </ul>
        </aside>
      </div>
    )
  }
}

export default SideMenu
