import React from 'react'
import Cookies from 'universal-cookie'
import { instanceOf } from 'prop-types'

import axios from 'axios'

const cookies = new Cookies()

// axios.defaults.baseURL = 'http://127.0.0.1:8787/api/noter'
axios.defaults.baseURL =
  'https://winter-unit-2a9e.igor-n-kuz8044.workers.dev/api/noter'

axios.defaults.headers.common['Authorization'] = cookies.get('auth')

class NavBarMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isNewPageModalActive: false,
      isSignInModalActive: false,
      auth: cookies.get('auth') || '',
      docname: '',
    }
  }

  showCreateNewPage = (val) => {
    this.setState({ isNewPageModalActive: true }, () => {})
  }

  hideCreateNewPage = (val) => {
    this.setState({ isNewPageModalActive: false }, () => {})
  }

  showSignInPage = (val) => {
    this.setState({ isSignInModalActive: true }, () => {})
  }

  hideSignInPage = (val) => {
    this.setState({ isSignInModalActive: false }, () => {})
  }

  handleChange(e) {
    this.setState({ docname: e.target.value })
    console.log(e.target.value)
  }

  handleUsernameChange(e) {
    this.setState({ username: e.target.value })
    console.log(e.target.value)
  }

  handlePasswordChange(e) {
    this.setState({ auth: e.target.value })
    console.log(e.target.value)
  }

  createNewPage = (val) => {
    axios.post(`/pages`, {
      name: this.state.docname,
    })
    this.hideCreateNewPage()
  }

  signIn = (val) => {
    const cookies = new Cookies()
    cookies.set('auth', this.state.auth, { path: '/' })

    this.hideSignInPage()
  }

  render() {
    return (
      <div>
        <nav class="navbar" role="navigation" aria-label="main navigation">
          <div class="navbar-brand">
            <a class="navbar-item" href="https://bulma.io">
              <img
                src="https://bulma.io/images/bulma-logo.png"
                width="112"
                height="28"
              />
            </a>

            <a
              role="button"
              class="navbar-burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div id="navbarBasicExample" class="navbar-menu">
            <div class="navbar-start">
              <div class="navbar-item has-dropdown is-hoverable">
                <a class="navbar-link">Menu</a>

                <div class="navbar-dropdown">
                  <a class="navbar-item" onClick={this.showCreateNewPage}>
                    Create New Document
                  </a>
                </div>
              </div>
            </div>

            <div class="navbar-end">
              <div class="navbar-item">
                <div class="buttons">
                  <button
                    class="button is-success"
                    onClick={this.showSignInPage}
                  >
                    Sign In
                  </button>

                  <a class="button is-light">Log in</a>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div
          class={`modal ${this.state.isNewPageModalActive ? 'is-active' : ''}`}
        >
          <div class="modal-background"></div>
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Modal title</p>
              <button
                class="delete"
                aria-label="close"
                onClick={this.hideCreateNewPage}
              ></button>
            </header>
            <section class="modal-card-body">
              Whould you like to create a new document?
              <input
                class="input"
                type="text"
                placeholder="Text input"
                onChange={this.handleChange.bind(this)}
              />
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" onClick={this.createNewPage}>
                Save changes
              </button>
              <button class="button" onClick={this.hideCreateNewPage}>
                Cancel
              </button>
            </footer>
          </div>
        </div>

        <div
          class={`modal ${this.state.isSignInModalActive ? 'is-active' : ''}`}
        >
          <div class="modal-background"></div>
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Sign In</p>
              <button
                class="delete"
                aria-label="close"
                onClick={this.hideCreateNewPage}
              ></button>
            </header>
            <section class="modal-card-body">
              {/* Login
              <input
                class="input"
                type="text"
                placeholder="Text input"
                onChange={this.handleUsernameChange.bind(this)}
              /> */}
              Password
              <input
                class="input"
                type="text"
                placeholder="Text input"
                onChange={this.handlePasswordChange.bind(this)}
              />
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" onClick={this.signIn}>
                Sign Up
              </button>
              <button class="button" onClick={this.hideSignInPage}>
                Cancel
              </button>
            </footer>
          </div>
        </div>
      </div>
    )
  }
}

export default NavBarMenu
